//const UIkit = window.UIkit;

export default function () {
    const navbar = document.querySelector(".cbx-navbar-sticky");
    const sticky = navbar?.offsetTop ?? 0;
    const controlHeight = (navbar?.clientHeight ?? 0) + sticky + window.innerHeight;

    function validateStickyNav() {
        if (window.scrollY > sticky && document.body.clientHeight > controlHeight) {
            document.querySelector(".cbx-navbar-sticky").classList.add("sticky");
            document.querySelector(".cbx-navbar-sticky").classList.add("cbx-header-background");
            document.body.style.paddingTop = (document.querySelector(".cbx-navbar-sticky").clientHeight - sticky) + "px";
        } else {
            document.querySelector(".cbx-navbar-sticky").classList.remove("sticky");
            document.querySelector(".cbx-navbar-sticky").classList.remove("cbx-header-background");
            document.body.style.paddingTop = null;
        }
    }

    if (navbar) {
        document.addEventListener('scroll', function () {
            validateStickyNav();
        });

        document.addEventListener("DOMContentLoaded", function () {
            validateStickyNav();
        });
    }
}